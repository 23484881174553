import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  width: 100%;
  padding: 24px;

  @media screen and (max-width: 991px) {
    background: ${(props) => props.theme.color.background};
    z-index: 2;
    width: calc(100% - 64px);
    margin: 0 auto;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    transform: translateY(calc(-55px));
    // Expected the 40px spacing between form and FeatureIn comp
    // set margin-bottom to negative number because i used translateY form to top.
    // The value is calculated by 40px - (translateY) - (translateY (from below style))
    // src/pages/SellCar/containers/SellCarRequestFormWrapperContainer.tsx
    margin-bottom: calc(40px - 55px - 16px);
  }

  @media screen and (max-width: 767px) {
    transform: calc(40px - 55px - 16px);
    width: calc(100% - 64px);
  }

  @media screen and (max-width: 575px) {
    transform: calc(40px - 55px - 16px);
    width: calc(100% - 32px);
  }

  @media screen and (max-width: 374px) {
    transform: calc(40px - 24px - 16px);
    width: calc(100% - 16px);
  }
`;

const SellCarRequestFormContainerSkeleton = () => (
  <StyledWrapper>
    <Skeleton active paragraph={{ rows: 10 }} />
  </StyledWrapper>
);

export default SellCarRequestFormContainerSkeleton;
