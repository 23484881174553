import React from 'react';
import styled from 'styled-components';
import { getStaticCDN } from '@design-system/utils/utils';
import { useTranslation } from 'react-i18next';
import StyledContainer from '@design-system/styled/Container';

const StyledInContainer = styled(StyledContainer)`
  @media screen and (min-width: 992px) {
    padding-top: 40px !important;
  }
`;

const StyledFeaturedInTitle = styled.div`
  text-align: center;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  ${(props) => props.theme.typo.familyGoogle.bold};
  color: ${(props) => props.theme.color.onSurface};
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media screen and (min-width: 992px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const StyledImage = styled.img`
  max-width: 135px;
  max-height: 32px;
`;

const StyledFeatureInWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  @media screen and (min-width: 768px) {
    gap: 36px;
  }
  @media screen and (min-width: 1280px) {
    gap: 23px;
  }
`;

type ImageType = {
  name: string;
  altText: string;
};
const images: ImageType[] = [
  {
    name: 'carro-car-selling-platform-featured-straitstimes.svg',
    altText: 'sellCar.featuredIn.featuredStraitstimes',
  },
  {
    name: 'carro-car-selling-platform-featured-businesstimes.png',
    altText: 'sellCar.featuredIn.featuredBusinesstimes',
  },
  {
    name: 'carro-car-selling-platform-featured-CNA.png',
    altText: 'sellCar.featuredIn.featuredCNA',
  },
  {
    name: 'carro-car-selling-platform-featured-CNBC.png',
    altText: 'sellCar.featuredIn.featuredCNBC',
  },
  {
    name: 'carro-car-selling-platform-featured-reuters.png',
    altText: 'sellCar.featuredIn.featuredReuters',
  },
  {
    name: 'carro-car-selling-platform-featured-today.png',
    altText: 'sellCar.featuredIn.featuredToday',
  },
  {
    name: 'carro-car-selling-platform-featured-e27.png',
    altText: 'sellCar.featuredIn.featuredE27',
  },
  {
    name: 'carro-car-selling-platform-featured-moneyfm.png',
    altText: 'sellCar.featuredIn.featuredMoneyfm',
  },
  {
    name: 'carro-car-selling-platform-featured-vulcan.svg',
    altText: 'sellCar.featuredIn.featuredVulcan',
  },
];

const getImageSrc = (isColored: boolean, imageFilename: string) =>
  getStaticCDN(`/static/img/sell-car/${isColored ? 'colored' : 'white'}/${imageFilename}`);

interface Props {
  isWithImageColor: boolean;
}
const FeaturedIn = ({ isWithImageColor }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledInContainer>
      <StyledFeaturedInTitle>{t('sellCar.featuredIn.featuredIn')}</StyledFeaturedInTitle>

      <StyledFeatureInWrapper>
        {images.map((image) => (
          <StyledImage
            src={getImageSrc(isWithImageColor, image.name)}
            alt={t(image.altText)}
            key={image.name}
            className={image.name.split('.')[0]}
          />
        ))}
      </StyledFeatureInWrapper>
    </StyledInContainer>
  );
};

export default FeaturedIn;
