import React from 'react';
import styled from 'styled-components';

import SellCarRequestFormInfoSkeleton from '@source/pages/SellCar/components/SellCarRequestForm/SellCarRequestFormInfo.skeleton';
import SellCarRequestFormContainerSkeleton from '@source/pages/SellCar/containers/SellCarRequestFormContainer.skeleton';

const StyledWrapper = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.background};

  display: flex;
  border-radius: 12px 12px 12px 12px;

  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    transform: translateY(-16px);
    width: 100%;
  }
`;

const SellCarRequestFormWrapperContainerSkeleton = ({ country }: { country: string }) => (
  <StyledWrapper id="request-form-wrapper">
    <SellCarRequestFormInfoSkeleton country={country} />
    <SellCarRequestFormContainerSkeleton />
  </StyledWrapper>
);

export default SellCarRequestFormWrapperContainerSkeleton;
