/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TRegionKey } from '@source/interface';
import * as actions from '@source/redux/slice/sellSlice';
import { getRecaptchaToken } from '@design-system/utils/utils';
import { useAppDispatch, useAppSelector } from '@source/hooks/useStateHook';

const useSellReduxServices = () => {
  const dispatch = useAppDispatch();

  const { isReady, pathname } = useRouter();
  const { i18n } = useTranslation();

  /** Selectors */
  const sellState = useAppSelector((state) => state.sell, shallowEqual);

  /** Dispatched Funcs  */
  const setFormInfo = useCallback((data: any) => dispatch(actions.setFormInfo(data)), [dispatch]);

  const createSellTicket = useCallback(
    ({
      country,
      payload,
      isAuthenticated = false,
    }: {
      country: TRegionKey;
      payload: Record<string, unknown>;
      isAuthenticated?: boolean;
    }) => {
      const currentPage = pathname.replace(/[^a-zA-Z]/g, '');
      const recaptchaAction = `${country}_${currentPage}_requestOtp`;

      const createSellTicketWithToken = (token: string) =>
        dispatch(
          actions.createSellTicket({
            country,
            payload: {
              ...payload,
              g_recaptcha_response: token,
              g_recaptcha_action: recaptchaAction,
            } as Record<string, unknown>,
            isAuthenticated,
          }),
        );
      return getRecaptchaToken(createSellTicketWithToken, createSellTicketWithToken, recaptchaAction);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isReady, i18n.language],
  );

  return {
    sellState,
    createSellTicket,
    setFormInfo,
  };
};

export default useSellReduxServices;
