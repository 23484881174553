/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { SELL_FORM_INFO_PROPS } from '@source/pages/SellCar/constants';
import { ScreenBreakPointInPixel, TLanguageKey, TRegionKey } from '@source/interface';
import { TitleTypography } from '@design-system/components';
import StyledContainer from '@design-system/styled/Container';

// Todo: Need to refactor the sell banner later.
// The form should be expanded to the bottom

export const StyledWrapper = styled.div<{
  bgImg1920?: string;
  bgImg1920Expanded?: string;
  bgImg1280?: string;
  bgImg1280Expanded?: string;
  bgImg992?: string;
  bgImg992Expanded?: string;
  bgImg768?: string;
  bgImg576?: string;
  bgImg375?: string;
  country?: string;
  isNewDesign?: boolean;
  [key: string]: any;
}>`
  --minBannerHeight: ${(props) => props.formWrapperOriginalHeight + 152 + 152}px;

  @media (max-width: 1919px) {
    ${(props) =>
      props.country === 'my'
        ? css`
            --minBannerHeight: ${props.formWrapperOriginalHeight + 60 + 60}px;
          `
        : css`
            --minBannerHeight: ${props.formWrapperOriginalHeight + 143 + 143}px;
          `}
  }

  @media (max-width: 1279px) {
    --minBannerHeight: ${(props) => props.formWrapperOriginalHeight + 64 + 64}px;
  }

  @media (max-width: 991px) {
    --minBannerHeight: 0;
  }

  position: relative;
  width: 100%;
  height: auto;
  min-height: var(--minBannerHeight);

  background: url(${(props) => props.bgImg1920Expanded}) no-repeat;
  background-size: cover;
  background-position: top;

  // Add overlay to background
  :before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.72) 0%,
      rgba(0, 0, 0, 0.23883) 33.34%,
      rgba(0, 0, 0, 0.0001) 47.42%
    );
  }

  .container {
    margin: 0 auto;
  }

  @media (max-width: 1919px) {
    background: url(${(props) => props.bgImg1280Expanded}) no-repeat;
    background-position: top left;
    background-size: cover;
    height: 100%;

    .container {
      margin-left: min(128px, 30%);
      margin-right: min(43px, 30%);
    }
  }

  @media (max-width: 1279px) {
    min-height: var(--minBannerHeight);

    ${(props) =>
      props.bgImg992 &&
      css`
        background: url(${props.bgImg992Expanded}) no-repeat;
      `}

    background-position: top left;
    background-size: cover;

    .container {
      margin-left: 64px;
    }
  }

  @media (max-width: 991px) {
    height: 425px;

    ${(props) =>
      props.bgImg768 &&
      css`
        background: url(${props.bgImg768}) no-repeat;
      `}
    background-position: top left;
    background-size: cover;

    .container {
      margin: auto 48px;
    }
  }

  @media (max-width: 767px) {
    height: 352px;

    ${(props) =>
      props.bgImg768 &&
      css`
        background: url(${props.bgImg576}) no-repeat;
      `}
    background-position: top left;
    background-size: cover;

    height: 352px;

    ${(props) =>
      props.isNewDesign &&
      css`
        height: 280px;
      `}

    .container {
      margin: auto 24px;
    }
  }

  @media (max-width: 575px) {
    height: 460px;
    ${(props) =>
      props.bgImg576 &&
      css`
        background: url(${props.bgImg375}) no-repeat;
      `}
    background-position: top left;
    background-size: cover;
    height: 460px;

    ${(props) =>
      props.isNewDesign &&
      css`
        height: 230px;
      `}

    .container {
      margin: 0px;
      padding-left: 15px;
    }
  }

  @media (max-width: 374px) {
    height: 350px;

    ${(props) =>
      props.isNewDesign &&
      css`
        height: 204px;
      `}
  }

  .container {
    height: 100%;
  }
`;

export const StyledContainerWrapper = styled(StyledContainer)`
  height: 100%;
`;

// Hero banner content - container
export const StyledContent = styled.div<{ country: string }>`
  position: relative;
  max-width: 1280px;
  width: 100%;
  margin: 0px auto;
  height: 100%;

  padding-top: 152px;

  @media screen and (max-width: 1919px) {
    ${(props) =>
      props.country === 'my'
        ? css`
            padding-top: 80px;
          `
        : css`
            padding-top: 143px;
          `}
  }

  @media screen and (max-width: 1279px) {
    padding-top: 64px;
  }

  @media screen and (max-width: 991px) {
    padding-top: 0px;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  height: 100%;
`;

// Left side hero banner
export const StyledLeftBanner = styled.div<SELL_FORM_INFO_PROPS>`
  display: flex;
  align-items: flex-end;
  width: fit-content;

  margin-right: 83px;

  height: ${(props) => props.formWrapperOriginalHeight}px; // Keep the height fixed although form is expanded in ID, MY

  ${(props) =>
    ['my', 'jp', 'hk'].includes(props.country || 'sg') &&
    props.expanded &&
    css`
      // If MY, the height is slightly different. I'm trying to follow the figma position
      height: ${(props?.formWrapperHeight || 0) - 152 + 15}px;

      @media screen and (max-width: 1919px) {
        height: ${(props?.formWrapperHeight || 0) - 143 + 15}px;
      }
    `}

  @media screen and (max-width: 1919px) {
    margin-right: 139px;
  }

  @media screen and (max-width: 1279px) {
    margin-right: 55px;
  }

  @media screen and (max-width: 991px) {
    height: auto;
    margin-bottom: calc(37px + 16px); // + 16px of translateY from Sell Request Form VSP
  }

  @media screen and (max-width: 767px) {
    height: auto;
    margin-bottom: calc(17px + 16px); // + 16px of translateY from Sell Request Form VSP
  }
`;

/**
 * Get hero banner text configs by current country, lang, size
 *
 * @param country
 * @param lang
 * @param minSize
 *
 * @returns [number, number] // first item: font-size, 2nd one is line height
 */
const getBannerTextConfigs = (
  country: TRegionKey,
  lang: TLanguageKey,
  minSize: ScreenBreakPointInPixel,
  isIOS: boolean,
) => {
  // each country's EN is using same config
  if (lang === 'en' || country === 'sg') {
    const configEN = {
      375: [32, 32],
      576: [32, 32],
      768: [32, 32],
      992: [36, 36],
      1280: [48, 48],
      1920: [48, 48],
    } as Record<string, number[]>;
    return configEN?.[minSize] || configEN[1920];
  }

  // Config by lang (except EN, assume each country use 1 lang)
  const configByCountry: Omit<Record<TLanguageKey, Record<ScreenBreakPointInPixel, number[]>>, 'en'> = {
    id: {
      375: [28, 32],
      576: [32, 32],
      768: [32, 32],
      992: [30, 32],
      1280: [40, 40],
      1920: [40, 40],
    },
    ms: {
      375: [28, 32],
      576: [32, 32],
      768: [32, 32],
      992: [28, 32],
      1280: [40, 40],
      1920: [40, 40],
    },
    th: {
      375: [32, isIOS ? 40 : 34],
      576: [36, 38],
      768: [36, 38],
      992: [40, 42],
      1280: [56, 58],
      1920: [56, 58],
    },
    'zh-SG': {
      375: [32, 34],
      576: [32, 34],
      768: [32, 34],
      992: [36, 38],
      1280: [48, 50],
      1920: [48, 50],
    },
    'zh-MY': {
      375: [32, 34],
      576: [32, 34],
      768: [32, 34],
      992: [36, 38],
      1280: [48, 50],
      1920: [48, 50],
    },
    ja: {
      375: [32, 34],
      576: [32, 34],
      768: [32, 34],
      992: [36, 38],
      1280: [48, 50],
      1920: [48, 50],
    },
    'ms-SG': {
      375: [28, 32],
      576: [32, 32],
      768: [32, 32],
      992: [28, 32],
      1280: [40, 40],
      1920: [40, 40],
    },
    'zh-Hant-HK': {
      375: [32, 34],
      576: [32, 34],
      768: [32, 34],
      992: [36, 38],
      1280: [48, 50],
      1920: [48, 50],
    },
    'zh-Hans-HK': {
      375: [32, 34],
      576: [32, 34],
      768: [32, 34],
      992: [36, 38],
      1280: [48, 50],
      1920: [48, 50],
    },
  };

  return configByCountry?.[lang]?.[minSize] || [48, 48];
};

// Hero banner text
export const StyledTitle = styled(TitleTypography)<{ country: TRegionKey; lang: TLanguageKey; isIOS: boolean }>`
  &&& {
    color: ${(props) => props.theme.color.background};
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    font-size: ${({ lang, country, isIOS }) => getBannerTextConfigs(country, lang, 1920, isIOS)[0]}px;
    font-weight: ${(props) => (props.lang === 'th' ? '700' : '600')};
    letter-spacing: 0.01em;
    line-height: ${({ lang, country, isIOS }) => getBannerTextConfigs(country, lang, 1920, isIOS)[1]}px;
    text-transform: capitalize;
    white-space: nowrap;
    word-spacing: 0.01em;

    .txt--orange {
      color: #ff773d;
    }

    @media screen and (max-width: 1919px) {
      font-size: ${({ lang, country, isIOS }) => getBannerTextConfigs(country, lang, 1280, isIOS)[0]}px;
      line-height: ${({ lang, country, isIOS }) => getBannerTextConfigs(country, lang, 1280, isIOS)[1]}px;
    }

    @media screen and (max-width: 1279px) {
      font-size: ${({ lang, country, isIOS }) => getBannerTextConfigs(country, lang, 992, isIOS)[0]}px;
      line-height: ${({ lang, country, isIOS }) => getBannerTextConfigs(country, lang, 992, isIOS)[1]}px;
    }

    @media screen and (max-width: 991px) {
      padding-left: 0px;
      line-height: 32px;
      font-size: ${({ lang, country, isIOS }) => getBannerTextConfigs(country, lang, 768, isIOS)[0]}px;
      line-height: ${({ lang, country, isIOS }) => getBannerTextConfigs(country, lang, 768, isIOS)[1]}px;
    }

    @media screen and (max-width: 767px) {
      font-size: ${({ lang, country, isIOS }) => getBannerTextConfigs(country, lang, 576, isIOS)[0]}px;
      line-height: ${({ lang, country, isIOS }) => getBannerTextConfigs(country, lang, 576, isIOS)[1]}px;
    }

    @media screen and (max-width: 575px) {
      padding-left: 0px;
      font-size: ${({ lang, country, isIOS }) => getBannerTextConfigs(country, lang, 375, isIOS)[0]}px;
      line-height: ${({ lang, country, isIOS }) => getBannerTextConfigs(country, lang, 375, isIOS)[1]}px;
    }
  }
`;

// Right side hero banner
export const StyledSellCarForm = styled.div<SELL_FORM_INFO_PROPS>`
  display: flex;
  align-items: flex-start;

  /* padding-bottom: ${(props) => (props.expanded ? '15px' : '64px')};

  @media screen and (min-width: 1280px) {
    padding-bottom: ${(props) => (props.expanded && props.country !== 'id' ? '15px' : '140px')};
  } */
  width: 650px;

  ${(props) =>
    props.country === 'id'
      ? css`
          margin-bottom: ${props.expanded ? '64px' : '152px'};
        `
      : props.country === 'my'
      ? css`
          margin-bottom: ${props.expanded ? '15px' : '48px'};
        `
      : css`
          margin-bottom: ${props.expanded ? '15px' : '152px'};
        `}

  @media (max-width: 1919px) {
    ${(props) =>
      props.country === 'id'
        ? css`
            margin-bottom: ${props.expanded ? '64px' : '152px'};
          `
        : props.country === 'my'
        ? css`
            margin-bottom: ${props.expanded ? '15px' : '48px'};
          `
        : css`
            margin-bottom: ${props.expanded ? '15px' : '152px'};
          `}
  }

  @media (max-width: 1279px) {
    margin-bottom: ${(props) => (props.expanded ? '15px' : '64px')};
  }
  /* height: 522px; */
  /* margin-top: 172px; */

  /* #request-form-wrapper {
    @media screen and (min-width: 992px) {
      margin: auto auto 100px auto;
    }
  } */
`;

// For Mobile view

// export const StyledBottomSellCarFormWrapper = styled.div<{ formWrapperHeight: number }>`
//   width: 100%;
//   height: ${(props) => props.formWrapperHeight + 20}px;
//   position: relative;

/* @media screen and (max-width: 992px) {
    height: calc(538px - (538px / 3));
  } */

/* @media screen and (max-width: 991px) {
    height: 418px;
  }

  @media screen and (max-width: 576px) {
    height: 430px;
  } */
// `;

// export const StyledBottomSellCarForm = styled.div`
//   width: 780px;
//   position: absolute;
//   bottom: 0;
//   left: 50%;
//   transform: translateX(-50%);
//   height: 100%;

//   /* @media screen and (max-width: 992px) {
//     width: 655px;
//   } */

//   @media screen and (max-width: 991px) {
//     width: calc(100% - 64px);
//   }

//   @media screen and (max-width: 576px) {
//     width: calc(100% - 32px);
//   }
// `;

export const StyledBottomFeaturedIn = styled.div`
  padding: 60px 62px;

  @media screen and (max-width: 992px) {
    padding: 60px 0;
  }
`;
