import React from 'react';
import i18next from 'i18next';
import { Skeleton } from 'antd';
import styled from 'styled-components';

import SellCarRequestFormInfoStyle from '@source/pages/SellCar/components/SellCarRequestForm/SellCarRequestFormInfo.style';

const StyledSkeleton = styled(Skeleton)``;

const SellCarRequestFormInfoSkeleton = ({ country }: { country: string }) => (
  <SellCarRequestFormInfoStyle country={country} lang={i18next.language}>
    {Array.from({ length: 3 }).map((_, idx) => (
      <div className={`info ${idx}`} key={Number(idx)}>
        <StyledSkeleton paragraph={{ rows: 2 }} active />
      </div>
    ))}
  </SellCarRequestFormInfoStyle>
);
export default SellCarRequestFormInfoSkeleton;
