/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { Suspense, useMemo } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';

import get from 'lodash/get';

// HOOKS
import { Trans, useTranslation } from 'react-i18next';
import useSellReduxServices from '@source/hooks/useSellReduxServices';
import useWindowResize from '@source/hooks/useWindowResize';
import useIsIOS from '@source/hooks/useIsIOS';

// CONSTANTS
import { SELL_CAR_HERO_BANNER } from '@source/pages/SellCar/constants';

// TYPES
import { TLanguageKey, TRegionKey } from '@source/interface';

import {
  StyledContent,
  StyledRow,
  StyledLeftBanner,
  StyledSellCarForm,
  StyledTitle,
  StyledWrapper,
  StyledContainerWrapper,
} from '@source/pages/SellCar/components/Banner/SellBanner.style';

import SellCarRequestFormContainerSkeleton from '@source/pages/SellCar/containers/SellCarRequestFormWrapperContainer.skeleton';

// import SellCarRequestFormWrapperContainer from '@source/pages/SellCar/containers/SellCarRequestFormWrapperContainer';
const SellCarRequestFormWrapperContainer = dynamic(
  () => import('@source/pages/SellCar/containers/SellCarRequestFormWrapperContainer'),
  {
    suspense: true,
  },
);

type SellBannerProps = {
  country?: TRegionKey;
};

const SellBanner = ({ country = 'sg' }: SellBannerProps) => {
  const { query } = useRouter();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language as TLanguageKey;
  const title = {
    text: `sellCar.banner.${country}.sellYourCarFor`,
    orangeText: `sellCar.banner.${country}.onlyTheBestPrice`,
  };
  const isNewDesign =
    country === 'my' &&
    [
      'PM_GG_MY_WEB_SELL_SEM_Sales_PTG_AlwaysOn_Generic_Br',
      '2024_PM_GG_MY_WEB_SELL_SEM_Sales_PTG_AlwaysOn_Generic_Ex',
    ].includes(query?.utm_campaign as string);
  const { titleKey, ...bgImgProps } = SELL_CAR_HERO_BANNER(country, isNewDesign);

  // Use useWindowSize instead of useMobileView. Because useMobileView's returning false boolean instead of undefined
  const [width] = useWindowResize();
  const isAboveTablet = width && width > 991;
  const isBelowTablet = width && width <= 991;

  //
  const isIOS = useIsIOS();

  //
  const { sellState } = useSellReduxServices();
  const { formInfo } = sellState;
  const { expanded } = formInfo || {};

  const bgKeyPath = useMemo(() => {
    let bgKey = null;
    const supportedScreenSizes = [375, 576, 768, 992, 1280, 1920];

    for (let index = 0; index < supportedScreenSizes.length; index += 1) {
      const ele = supportedScreenSizes[index];

      if (!width) bgKey = 'bgImg375';

      if (width <= supportedScreenSizes[0]) {
        bgKey = 'bgImg375';
        break;
      }
      if (width >= supportedScreenSizes[supportedScreenSizes.length - 1]) {
        bgKey = 'bgImg1920';
        break;
      }

      const nextEle = supportedScreenSizes[index + 1];

      if (width >= ele && width < nextEle) {
        bgKey = `bgImg${ele}`;
      }
    }

    return `${bgKey}${expanded && country !== 'id' ? 'Expanded' : ''}`;
  }, [width, expanded]);

  const imageSrc = get(bgImgProps, bgKeyPath);
  const blurSrc = get(bgImgProps, `${bgKeyPath}Blur`);

  return (
    <>
      <StyledWrapper {...formInfo} country={country} isNewDesign={isNewDesign}>
        <Image
          alt="banner"
          src={imageSrc}
          layout="fill"
          objectFit="cover"
          objectPosition="top left"
          priority
          blurDataURL={blurSrc}
          placeholder="blur"
        />
        <StyledContainerWrapper>
          <StyledContent country={country}>
            <StyledRow>
              <StyledLeftBanner {...formInfo} country={country}>
                <StyledTitle lang={currentLang} country={country} isIOS={isIOS}>
                  <Trans
                    i18nKey={titleKey}
                    components={{
                      br: (
                        <>
                          <br />
                          <span> </span>
                        </>
                      ),
                      fragment: <></>,
                      span: <span />,
                    }}
                    values={
                      isNewDesign && width <= 767
                        ? { text: `${t(title.text)}<br/>${t(title.orangeText)}`, orangeText: '' }
                        : { text: t(title.text), orangeText: t(title.orangeText) }
                    }
                  />
                </StyledTitle>
              </StyledLeftBanner>

              {isAboveTablet && (
                <StyledSellCarForm id="sell-car-form" country={country} {...formInfo}>
                  <SellCarRequestFormWrapperContainer isNewDesign={isNewDesign} />
                </StyledSellCarForm>
              )}
            </StyledRow>
          </StyledContent>
        </StyledContainerWrapper>
      </StyledWrapper>

      {isBelowTablet && (
        <Suspense fallback={<SellCarRequestFormContainerSkeleton country={country} />}>
          <SellCarRequestFormWrapperContainer isNewDesign={isNewDesign} />
        </Suspense>
      )}
    </>
  );
};

SellBanner.defaultProps = {
  country: 'sg',
};

export default SellBanner;
