import { GetServerSideProps } from 'next';
import { stringify } from 'querystring';
import { omit } from 'lodash';

import SellCar from '@source/pages/SellCar';
import { IRegionOptions, TLanguageKey, TRegionKey } from '@source/interface';
import { CARRO_MOBILE_APP_KEY, COOKIE_KEYS } from '@source/constants/common';
import {
  formatLanguageCodeInUrl,
  formatReqUrl,
  getCurrentRegionV2,
  // getCurrentRegion,
  getLanguageInURL,
  getSellUrlNameByLocalLanguage,
  // isMyTukarWeb,
} from '@source/utils/utils';
import { SELL_URL_NAME, URL_CONFIG } from '@source/constants/urlConfig';
import { getStrapiCentreLocationOptions, getStrapiCentres } from '@source/services/Strapi';
import { IInspectionCentre } from '@source/pages/SellCar/components/InspectionCentres/configs/types';

// const SellPage = ({ region }: { region: TRegionKey }) => <SellCar region={region} />;

export const getServerSideProps: GetServerSideProps = async ({ locale, req, res, query }) => {
  // Todo: Mostly copy paste below pieces of code from home GetServerSideProps first.
  // might need to update getCurrentRegion or create a new one for getting region and isFromMobileApp
  const isFromMobileApp = req.cookies?.[CARRO_MOBILE_APP_KEY] === 'true';
  const cookieRegion = req.cookies?.[COOKIE_KEYS.STORED_REGION];

  const { currentRegion, isDetected } = getCurrentRegionV2({
    urlRegion: locale as TRegionKey,
    cookieRegion: cookieRegion as TRegionKey,
    cloudflareCountry: req.headers?.['cf-ipcountry'] as TRegionKey,
  });

  const urlLanguage = getLanguageInURL(formatReqUrl(req?.url as string, locale as TRegionKey));
  const language = formatLanguageCodeInUrl(urlLanguage as TLanguageKey);
  let serverLocationCentreOptions = [];
  let filteredlocationCentres = [];

  if (
    (locale === 'id' && language === 'id' && !req?.url?.includes(SELL_URL_NAME.id)) ||
    (locale === 'id' && language === 'en' && req?.url?.includes(SELL_URL_NAME.id)) ||
    (locale === 'my' && language === 'ms' && !req?.url?.includes(SELL_URL_NAME.my)) ||
    (locale === 'my' && language === 'en' && req?.url?.includes(SELL_URL_NAME.my)) ||
    (locale === 'my' && language === 'zh-MY' && req?.url?.includes(SELL_URL_NAME.my))
  ) {
    const sellUrlName = getSellUrlNameByLocalLanguage(locale, language);
    const queries = stringify(omit(query, ['lang']));

    const sellUrl = URL_CONFIG({
      region: locale as TRegionKey,
      lang: language,
      path: `${sellUrlName}`,
      query: queries,
    }).formatted;
    res.writeHead(301, { Location: `${sellUrl}` });
    res.end();
  }

  if (isDetected) {
    try {
      serverLocationCentreOptions = await getStrapiCentreLocationOptions(currentRegion as TRegionKey, {
        isSellCentre: true,
      }).catch(() => []);
      const serverLocationCentres = await getStrapiCentres(currentRegion as TRegionKey).catch(() => []);
      filteredlocationCentres = serverLocationCentres.filter((centre: IInspectionCentre) => centre?.services?.sell);
    } catch (error) {
      console.log(error);
    }
  }

  return {
    props: {
      region: currentRegion,
      isFromMobileApp,
      lang: language,
      locationCentreOptions: serverLocationCentreOptions,
      locationCentres: filteredlocationCentres,
    }, // will be passed to the page component as props
  };
};

export default SellCar;
