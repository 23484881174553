import StyledContainer from '@design-system/styled/Container';
import styled, { css } from 'styled-components';

const getFontCSSConfig = (configs: number[], type: string) =>
  css`
    ${type} {
      font-size: ${configs[0]}px;
      line-height: ${configs[1]}px;
      font-weight: ${configs[2]};
    }
  `;

const fontClassConfigs = {
  l1HeaderBold: [24, 32, 700],
  l2HeaderBold: [28, 36, 700],
  mHeaderBold: [20, 28, 700],
  sHeaderSemi: [16, 24, 600],
  bodySemi: [14, 22, 600],
  bodyRegular: [14, 22, 400],
  footnoteSemi: [12, 20, 600],
  footnoteRegular: [12, 20, 400],
};

const widthInfoConfigs = {
  id_992: '141px',
  my_992: '141px',
  sg_992: '130px',
  th_375: '122px',
  th_992: '135px',
  tw_992: '133px',
} as Record<string, string>;

/**
 * Get VPS Number & Unit Text Configs
 *
 * @param country
 * @param lang
 * @param size
 * @returns css
 */
const getVPSFontConfigs = (country: string, lang: string, size: number) => {
  const { l2HeaderBold, bodySemi, bodyRegular, footnoteSemi, footnoteRegular, l1HeaderBold, sHeaderSemi, mHeaderBold } =
    fontClassConfigs || {};
  // default
  let fontConfigs = [l2HeaderBold, bodySemi, bodyRegular];

  if (['sg'].includes(country) && lang === 'en') {
    switch (size) {
      case 375:
      case 576:
        break;
      case 768:
        fontConfigs = [l2HeaderBold, bodySemi, bodySemi];
        break;
      case 992:
        fontConfigs = [l1HeaderBold, sHeaderSemi, bodySemi];
        break;
      default:
        break;
    }
  } else if (['jp', 'hk'].includes(country)) {
    switch (size) {
      case 375:
        fontConfigs = [l2HeaderBold, bodySemi, footnoteSemi];
        break;
      case 576:
      case 768:
        fontConfigs = [l2HeaderBold, bodySemi, bodySemi];
        break;
      case 992:
        fontConfigs = [l1HeaderBold, sHeaderSemi, bodySemi];
        break;
      default:
        break;
    }
  } else if (['my', 'id'].includes(country)) {
    switch (size) {
      case 375:
        fontConfigs =
          country === 'my' ? [sHeaderSemi, bodyRegular, bodyRegular] : [sHeaderSemi, footnoteRegular, footnoteRegular];
        break;
      case 576:
      case 992:
        fontConfigs = [mHeaderBold, bodyRegular, bodyRegular];
        break;
      case 768:
        fontConfigs = [l1HeaderBold, bodyRegular, bodyRegular];
        break;
      default:
        break;
    }
  } else if (country === 'th') {
    switch (size) {
      case 375:
        fontConfigs = [sHeaderSemi, sHeaderSemi, sHeaderSemi];
        break;
      case 576:
        fontConfigs = [mHeaderBold, mHeaderBold, mHeaderBold];
        break;
      case 768:
        fontConfigs = [l1HeaderBold, l1HeaderBold, l1HeaderBold];
        break;
      case 992:
        fontConfigs = [mHeaderBold, mHeaderBold, mHeaderBold];
        break;
      default:
        break;
    }
  }

  return css`
    ${getFontCSSConfig(fontConfigs[0], 'span.header')}
    ${getFontCSSConfig(fontConfigs[1], 'span.subHeader')}
    ${getFontCSSConfig(fontConfigs[2], 'span.desc')}

    a.link {
      color: ${(props) => props.theme.color.background};
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      text-decoration: underline;
    }
  `;
};

const SellCarRequestFormInfoStyle = styled(StyledContainer)<{ country: string; lang: string; isNewDesign?: boolean }>`
  padding-top: 20px;
  padding-bottom: 24px;
  border-radius: 12px 12px 0px 0px;
  height: auto;
  width: 100%;

  min-height: 160px;
  padding-bottom: 40px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  ${(props) => props.theme.typo.familyGoogle.regular};
  background-color: ${(props) => props.theme.color.primaryBase};

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;

  ${(props) =>
    props.isNewDesign &&
    css`
      @media screen and (max-width: 767px) {
        min-height: 0;
        padding-top: 30px !important;
        padding-bottom: 16px !important;
      }
    `}

  .info {
    grid-row: 1;
    line-height: 14px;
    color: ${(props) => props.theme.color.background}; //#ffffff
    max-height: 150px;
    font-weight: 600;
    padding-top: 0px;
    padding-bottom: 5px;
    margin-bottom: 8px;
    min-height: fit-content;
    z-index: 1;
    position: relative;
    font-size: 13px;

    /* min-width: ${(props) => widthInfoConfigs?.[`${props.country}_375`] || '130px'}; */

    /* &:first-of-type {
      padding-top: 0px;
    }

    &:last-of-type {
      padding-bottom: 0px;
    } */

    &:before {
      content: '';
      position: absolute;
      left: 0%;
      top: 6px;
      height: calc(100% - 13px);

      border-left: 1px solid ${(props) => props.theme.color.background};
    }

    &.info1 {
      padding-left: 8px;
      padding-right: 6px;
      ${(props) => getVPSFontConfigs(props.country, props.lang, 375)}
    }

    &.info2 {
      padding-left: 8px;
      padding-right: 6px;
      ${(props) => getVPSFontConfigs(props.country, props.lang, 375)}
    }

    &.info3 {
      padding-left: 8px;
      padding-right: 0;
      ${(props) => getVPSFontConfigs(props.country, props.lang, 375)}
    }
  }

  @media screen and (min-width: 375px) {
    padding-top: 20px;
    padding-bottom: 55px;
  }

  @media screen and (min-width: 576px) {
    padding-top: 20px;
    padding-bottom: 55px;
    .info {
      min-width: 150px;
      &.info1 {
        ${(props) => getVPSFontConfigs(props.country, props.lang, 576)}
      }

      &.info2 {
        ${(props) => getVPSFontConfigs(props.country, props.lang, 576)}
      }

      &.info3 {
        ${(props) => getVPSFontConfigs(props.country, props.lang, 576)}
      }
    }
  }

  @media screen and (min-width: 768px) {
    .info {
      &.info1 {
        ${(props) => getVPSFontConfigs(props.country, props.lang, 768)}
      }

      &.info2 {
        ${(props) => getVPSFontConfigs(props.country, props.lang, 768)}
      }

      &.info3 {
        ${(props) => getVPSFontConfigs(props.country, props.lang, 768)}
      }
    }
  }

  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 185px;
    flex-shrink: 0;

    ${(props) => props.theme.typo.familyGoogle.regular};
    background-color: ${(props) => props.theme.color.primaryBase};
    padding: 20px 20px;
    display: block;
    border-radius: 12px 0px 0px 12px;
    width: unset;

    .info {
      line-height: 18px;
      color: ${(props) => props.theme.color.background}; //#ffffff
      padding: 8px 0px;
      min-width: ${(props) => widthInfoConfigs?.[`${props.country}_992`] || '130px'};
      max-height: 150px;
      font-weight: 600;
      font-size: 14px;
      position: static;
      /* min-width: unset; */
      flex: 1;

      &:first-of-type {
        padding-top: 0px;
      }

      &:last-of-type {
        padding-bottom: 0px;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.color.background};
      }

      &:before {
        display: none;
      }

      &.info1 {
        padding-left: 0px;
        padding-right: 0px;
        ${(props) => getVPSFontConfigs(props.country, props.lang, 992)}
      }

      &.info2 {
        padding-left: 0px;
        padding-right: 0px;
        ${(props) => getVPSFontConfigs(props.country, props.lang, 992)}
      }

      &.info3 {
        padding-left: 0px;
        padding-right: 0px;
        ${(props) => getVPSFontConfigs(props.country, props.lang, 992)}
      }
    }
  }
`;

export default SellCarRequestFormInfoStyle;
