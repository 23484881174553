import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import jsCookie from 'js-cookie';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

// COMPONENTS
import Layout from '@source/components/Layout';

// HOOKS
// import useRegionConfig from '@source/hooks/useRegionConfig';

// TYPES
import type { NextPage } from 'next';
import { IRegionProps, TRegionKey } from '@source/interface';

import HeadMeta from '@source/pages/Home/components/HeadMeta';
import { STEPS_TO_SELL_CAR_CONTENT } from '@source/pages/SellCar/constants';
import CUSTOMER_REVIEW_CONTENT from '@source/components/CustomerReview/content';
import { CARRO_MOBILE_APP_KEY } from '@source/constants/common';

// First load Sell Banner
import SellBanner from '@source/pages/SellCar/components/Banner';
import FeaturedIn from '@source/components/FeaturedIn';
import useWindowResize from '@source/hooks/useWindowResize';
import useUTM from '@source/hooks/useUTM';
import { IInspectionCentre, ILocationCentreOption } from './components/InspectionCentres/configs/types';

const Stats = dynamic(() => import('@source/pages/SellCar/components/Stats'));
// const FeaturedIn = dynamic(() => import('@source/components/FeaturedIn'));
const AskFaq = dynamic(() => import('@source/pages/SellCar/components/AskFaq'));
const StepsToSellCar = dynamic(() => import('@source/pages/SellCar/components/StepsToSellCar'));
const CustomerReview = dynamic(() => import('@source/components/CustomerReview/CustomerReview'));
const InspectionCentres = dynamic(() => import('@source/pages/SellCar/components/InspectionCentres'), {
  ssr: false,
});
// const SellBanner = dynamic(
//   () => import('@source/pages/SellCar/components/Banner') as LoaderComponent<{ country?: string }>,
// );

interface SellCarProps {
  region: TRegionKey;
  locationCentreOptions: ILocationCentreOption[];
  locationCentres: IInspectionCentre[];
}

const StyledLeftBanner = styled.div``;

// Todo: Might need to think better way
// use region from getServerProps instead of using useRegionConfig
// because bro @huy got issue when get country in HeadMeta. Its undefined at first and HeadMeta doesn't rerender
// so we updated countryCode or country to be region from server side
const SellCar: NextPage<SellCarProps> = ({
  region: countryCode,
  locationCentreOptions,
  locationCentres,
}: SellCarProps) => {
  const { i18n } = useTranslation();
  const [isFromMobileApp, setIsFromMobileApp] = useState<boolean>(false);
  const [width] = useWindowResize();

  // save UTM referrer to cookie
  useUTM();

  useEffect(() => {
    const isFromMobileAppCookie = jsCookie.get(CARRO_MOBILE_APP_KEY);
    setIsFromMobileApp(isFromMobileAppCookie === 'true');
  }, []);

  const isJP = ['jp'].includes(countryCode);
  const isMY = countryCode === 'my';
  const sellStepTransPathByCountry = i18n.exists(`sellCar.sellSteps${countryCode.toUpperCase()}`)
    ? countryCode.toUpperCase()
    : '';

  // TODO: @cris
  // remove spin to resolve CXBF-1897
  // need to resolve CCV-702 again

  return (
    <Layout
      bannerContent={<SellBanner country={countryCode} />}
      hideHeader={isFromMobileApp}
      hideFooter={isFromMobileApp}
      hideSocialChat={false}
    >
      <HeadMeta metaKey="sell" region={countryCode as TRegionKey} />
      <StyledLeftBanner>
        {!isMY && <FeaturedIn isWithImageColor />}
        <StepsToSellCar
          countryCode={countryCode}
          backgroundColor="transparent"
          stepContent={STEPS_TO_SELL_CAR_CONTENT(sellStepTransPathByCountry, countryCode === 'my')} // pass countryCode as sellStepTransPathByCountry does not exist in my-MS
        />
        {!isJP && <CustomerReview content={CUSTOMER_REVIEW_CONTENT[countryCode as TRegionKey]} />}
        {!isMY && <Stats />}
        {isMY && (
          <InspectionCentres
            countryCode={countryCode}
            locationOptions={locationCentreOptions}
            inspectionCentres={locationCentres}
          />
        )}
        {!isJP && <AskFaq countryCode={countryCode || 'sg'} />}
      </StyledLeftBanner>
    </Layout>
  );
};
export default SellCar;
